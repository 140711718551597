/** @format */

import React from "react";

const Escrow = () => {
  return (
    <>
      <div className='Escrow_sec'>
        <div className='container'>
          <div className='bg_img'>
            <div className='row'>
              <div className='col-md-4 d-flex justify-content-center'>
                <div className='img_main' data-aos='fade-down'>
                  <img
                    src='assets/images/logo.png'
                    className='img-fluid'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-md-8 m-auto ' data-aos='zoom-in'>
                <h2>HOC Mission & Vision </h2>
                <p className="mt-3">
                  Our coin will stay only on the DEX and not the CEX because of
                  centralization issues which are very much a threat to many
                  systems. Because HOC believes that there are other ways which
                  the now ruling leaders in the markets can adapt their role and
                  position into a more valuable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Escrow;
