/** @format */

import React from "react";

const Roadmap = () => {
  return (
    <section className='timeline_area section_padding_130'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-8 col-lg-6'>
            {/* Section Heading*/}
            <div className='section_heading text-center mb-3'>
              <h2>Future Roadmap</h2>
              {/* <h3>A brief stories of our 2 years company journey</h3> */}
              <div className='line' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            {/* Timeline Area*/}
            <div className='apland-timeline-area'>
              {/* Single Timeline Content*/}
              <div className='single-timeline-area'>
                <div
                  className='timeline-date wow fadeInLeft'
                  data-wow-delay='0.1s'
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInLeft",
                  }}>
                  <p>9.1 Full Decentralization</p>
                </div>
                <div className='row'>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.3s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.3s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-address-card' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        <h6>7-Year Plan:</h6>
                        <p>
                          Platform development ,property acquisition,DEX
                          Integration,and Metaverse inclusion
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.5s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.5s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-archive' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        {/* <h6>Fixed bug</h6> */}
                        <p>Transition to a fully decentralized DAO.</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.7s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.7s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-address-book' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        {/* <h6>Reach 1k Users</h6> */}
                        <p>Empower the community in decision-making.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Single Timeline Content*/}
              <div className='single-timeline-area'>
                <div
                  className='timeline-date wow fadeInLeft'
                  data-wow-delay='0.1s'
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInLeft",
                  }}>
                  <p>9.2 HOC DEX Decentralized Trading</p>
                </div>
                <div className='row'>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.3s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.3s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-briefcase' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        {/* <h6>Updated 4.4.0</h6> */}
                        <p>Prioritize user autonomy and transparency.</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.5s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.5s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-desktop' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        {/* <h6>Fixed bug</h6> */}
                        <p>Develop a secure DEX</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.5s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.5s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-desktop' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        {/* <h6>Fixed bug</h6> */}
                        <p>
                          Focus on HOC token trading pairs and and Integration
                          with the ecosystem.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Single Timeline Content*/}
              <div className='single-timeline-area'>
                <div
                  className='timeline-date wow fadeInLeft'
                  data-wow-delay='0.1s'
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInLeft",
                  }}>
                  <p>9.3 Metaverse</p>
                </div>
                <div className='row'>
                  <div className='col-12 col-md-6 col-lg-3'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.3s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.3s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-id-card' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        {/* <h6>Updated 4.0</h6> */}
                        <p>Virtual property exploration,</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.5s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.5s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-desktop' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        {/* <h6>Fixed bug</h6> */}
                        <p>Seamless integration with the metaverse</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.7s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.7s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-picture-o' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        {/* <h6>Reach 500 Users</h6> */}
                        <p>virtual property exploration</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3'>
                    <div
                      className='single-timeline-content d-flex wow fadeInLeft'
                      data-wow-delay='0.7s'
                      style={{
                        visibility: "visible",
                        animationDelay: "0.7s",
                        animationName: "fadeInLeft",
                      }}>
                      <div className='timeline-icon'>
                        <i className='fa fa-picture-o' aria-hidden='true' />
                      </div>
                      <div className='timeline-text'>
                        {/* <h6>Reach 500 Users</h6> */}
                        <p>Introduction of VR Glasses</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
