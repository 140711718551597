/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaBook } from "react-icons/fa";
import { AiOutlineForm } from "react-icons/ai";
import Toggle from "../Utils/Toggle";

import { MdSwapCalls } from "react-icons/md";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const headerStyle = {
    backgroundColor: scrolling ? "var(--navBg)" : "",
    marginTop: scrolling ? "0%" : "0px",
    backdropFilter: scrolling ? "blur(5px)" : "blur(0px)",
  };

  return (
    <header>
      {/* <div className='top_div'>
        <div className='inner_div'>
          <p>Close faster and more securely with HOC</p>
          <Link>
            Try it now <FaLongArrowAltRight color='#38a6fa' />
          </Link>
        </div>
      </div> */}
      <nav
        className='navbar navbar-expand-lg fixed-top '
        id='header'
        style={headerStyle}>
        <div className='container'>
          <Link
            className='navbar-brand d-flex align-items-center'
            style={{ width: "10%" }}
            to='/'>
            <img
              src='assets/images/logo.png'
              className='img-fluid '
              style={{ width: "50%" }}
              alt='logo'
            />
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div
            className='collapse navbar-collapse '
            id='navbarSupportedContent'>
            <ul className='navbar-nav m-auto mb-2 mb-lg-0'>
              <li className='nav-item '>
                <Link className='nav-link buy ' aria-current='page' to='/'>
                  Home
                </Link>
              </li>

              <li className='nav-item'>
                <Link className='nav-link ' to='/about'>
                  About
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link className='nav-link ' to='/contact'>
                  Contact Us
                </Link>
              </li> */}
              <li className='nav-item'>
                <Link className='nav-link ' to='/blogAndVlog'>
                  Blog & Vlog
                </Link>
              </li>
              {/* <li className='nav-item ' onClick={toggleDropdown}>
                <Link className='nav-link drop  position-relative  '>
                  More <IoIosArrowDown />
                </Link>
              </li> */}
            </ul>
            <span className='navbar-text'>
              <ul className='navbar-nav me-auto mb-2 mb-lg-0 '>
                <Toggle />
                {/* <li className='nav-item mx-3'>
                  <a
                    className='btn-style'
                    href='/whitepaper.pdf'
                    rel='noreferrer'
                    target='_blank'>
                    <FaBook /> &nbsp; Whitepaper
                  </a>
                </li> */}
                <li className='nav-item mx-1'>
                  <a
                    className='btn-style'
                    href='https://dex.myhoc.io/'
                    target='_blank'
                    rel='noreferrer'>
                    <MdSwapCalls /> &nbsp; Dex
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='btn-style'
                    href='https://homeownersclub.io/'
                    target='_blank'
                    rel='noreferrer'>
                    &nbsp; About HOC
                  </a>
                </li>
              </ul>
            </span>
          </div>
        </div>
        {/* {isDropdownOpen ? (
          <div style={{ marginTop: "15rem" }} className={"navd "}>
            <div className='container'>
              <div className='row p-4'>
                <div className='col-md-12 mt-4 col-6 nav_hov'>
                  <ul>
                    <li className='nav-item'>
                      <Link
                        className=''
                        to='/termsAndConditions'
                        >
                        Term & Condition
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        className=''
                        to='/privacyPolicy'
                        >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null} */}
      </nav>
    </header>
  );
};

export default Header;
