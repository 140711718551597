/** @format */

import React from "react";

const Tokenomics = () => {
  return (
    <div className='container'>
      <div className='row'>
        <img
          src='https://imgur.com/blfgQoz.png'
          className='w-50 m-auto'
          alt=''
        />
      </div>
    </div>
  );
};

export default Tokenomics;
