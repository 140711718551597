import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Base from './components/Layouts/Base';
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Login from './pages/Login';
import Register from './pages/Register'
import Contact from './pages/Contact'
import AboutUs from './pages/AboutUs'
import Affiliates from './pages/Affiliates'
import AssetManagers from './pages/AssetManagers'
import BankingSolutions from './pages/BankingSolutions'
import BlogAndVlog from './pages/BlogAndVlog'
import Coin from './pages/Coin'
import CookiePolicy from './pages/CookiePolicy'
import Dex from './pages/Dex'
import EcoPlayer from './pages/EcoPlayer'
import FinancingAndFunding from './pages/FinancingAndFunding'
import HomeOwener from './pages/HomeOwener'
import Investors from './pages/Investors'
import LawyersAndNotaries from './pages/LawyersAndNotaries'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ProjectDevelopers from './pages/ProjectDevelopers'
import RealStateBroker from './pages/RealStateBroker'
import Tenants from './pages/Tenants'
import TermsAndConditions from './pages/TermsAndConditions'
import { keepTheme } from "./components/Utils/Theme";
import ReactGA from 'react-ga';
ReactGA.initialize('G-0F9S6Q4NGE');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  useEffect(() => {
    AOS.refresh();
    keepTheme();
    AOS.init({
      duration : 1000
    });
  }, []);
  return (
    <div className="App">
       <BrowserRouter>
      <Base>
        <Routes>
          <Route index path="/" element={<Home/>} />
          <Route  path="/contact" element={<Contact/>} />
          <Route  path="/about" element={<AboutUs/>} />
          <Route  path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/affiliates" element={<Affiliates/>} />
          <Route path="/assetManagers" element={<AssetManagers/>} />
          <Route path="/bankingSolutions" element={<BankingSolutions/>} />
          <Route path="/blogAndVlog" element={<BlogAndVlog/>} />
          <Route path="/coin" element={<Coin/>} />
          <Route path="/cookiePolicy" element={<CookiePolicy/>} />
          <Route path="/dex" element={<Dex/>} />
          <Route path="/ecoPlayer" element={<EcoPlayer/>} />
          <Route path="/financingAndFunding" element={<FinancingAndFunding/>} />
          <Route path="/homeOwener" element={<HomeOwener/>} />
          <Route path="/investors" element={<Investors/>} />
          <Route path="/lawyersAndNotaries" element={<LawyersAndNotaries/>} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy/>} />
          <Route path="/projectDevelopers" element={<ProjectDevelopers/>} />
          <Route path="/realStateBroker" element={<RealStateBroker/>} />
          <Route path="/tenants" element={<Tenants/>} />
          <Route path="/termsAndConditions" element={<TermsAndConditions/>} />
          {/* <Route path="/*" element={<PageNotFound />}/> */}
        </Routes>
      </Base>
    </BrowserRouter>
    </div>
  );
}

export default App;
