/** @format */

import React from "react";
import Faq from "react-faq-component";

const Faqs = () => {
  const data = {
    //   title: "FAQ (How it works)",
    rows: [
      {
        title: "What is Home Owners Club (HOC)?",
        content: `Home Owners Club (HOC) is a revolutionary platform that allows individuals to collectively invest in real estate and property, making property ownership accessible to a broader audience. It combines blockchain technology, a user-friendly app, and metaverse integration to redefine the real estate landscape.`,
      },
      {
        title: "How does HOC work?",
        content: `HOC enables users to pool their resources to invest in properties. The platform uses blockchain for transparency and security, and it offers features like property management, rental services, and a decentralized exchange (DEX) for trading HOC tokens.`,
      },

      {
        title: "What is the metaverse integration in HOC?",
        content: `The metaverse integration in HOC means that users can explore virtual representations of real-world properties and engage in immersive experiences related to real estate. It's like stepping into a digital world where you can interact with properties and other users.`,
      },

      {
        title: "Is HOC easy to use?",
        content: `Yes, HOC is designed to be user-friendly. You can easily register, invest, manage properties, and even explore the metaverse, all from your smartphone. The platform aims to simplify real estate investment.`,
      },
      {
        title: "How is HOC different from traditional real estate investment?",
        content: `HOC democratizes real estate investment by allowing collective ownership, reducing entry barriers, and increasing transparency. Unlike traditional methods, it's more inclusive and community-driven.`,
      },
      {
        title: "Is my data secure on HOC?",
        content: `Yes, HOC takes data security seriously. It employs advanced encryption and follows strict Know Your Customer (KYC) and Anti-Money Laundering (AML) procedures to protect user data.`,
      },
      {
        title: "What is the HOC Coin (HOC)?",
        content: `HOC Coin is the native cryptocurrency of the platform. It has various use cases within the ecosystem, including property investments, rental payments, transaction fees, and more.`,
      },
      {
        title: "Can I become a member of HOC?",
        content: `Yes, HOC welcomes members from all backgrounds. Whether you're a homeowner, investor, or simply interested in real estate, you can join the HOC community.`,
      },
      {
        title: "What are the future plans for HOC?",
        content: `HOC has ambitious plans, including transitioning into a fully decentralized autonomous organization (DAO), launching a decentralized exchange (DEX), and expanding into the metaverse for immersive experiences.`,
      },
      {
        title: "How can I get involved with HOC?",
        content: `You can start by visiting the HOC website, exploring the platform, and considering various membership options. Feel free to reach out to HOC for partnership inquiries or more information.`,
      },
    ],
  };

  const styles = {
    // bgColor: "#0E0E1DBF",
    titleTextColor: "var(--cardBg)",
    rowTitleColor: "var(--cardBg)",
    rowContentColor: "var(--cardBg)",
    arrowColor: "var(--cardBg)",
    rowContentPaddingTop: "10px",
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "30px",
    rowContentPaddingRight: "30px",
  };

  const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
    expandIcon: "+",
    collapseIcon: "-",
    margin: "2px",
  };

  return (
    <div className='container-fluid faqs'>
      <div className='container'>
        <div className='section-heading text-center'>
          <h2 className='accent-large'>FAQ</h2>
          <p className='sec-title'>Frequently Asked Questions</p>
        </div>

        <div className='mt-4'>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
    </div>
  );
};

export default Faqs;
