import React from 'react'

const RealStateBroker = () => {
  return (
    <>
    <div className="bg_img2">
        <div className="container">
          <h3>Real State Broker</h3>
          <div className="d-flex justify-content-center mt-4">
          </div>
        </div>
      </div>
  </>
  )
}

export default RealStateBroker