/** @format */

import React from "react";

import Modal from "react-bootstrap/Modal";

const MyModal = props => {
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
        KYC/AML registration
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          KYC (Know Your Customer) and AML (Anti-Money Laundering) proof are
          crucial steps in ensuring a secure and compliant environment within
          the Home Owners Club (HOC) ecosystem. These measures are designed to
          protect our community members and the integrity of the platform.
          Here's how the process works:
        </p>
        <h4 className='mt-4'>KYC (Know Your Customer):</h4>
        <ul>
          <li>
            Actionable Steps: Members are required to provide valid
            identification documents, such as a government-issued ID, passport,
            or driver's license.
          </li>
          <li>
            Details Needed: Full name, date of birth, residential address, and a
            clear image of the ID document.
          </li>
        </ul>
        <h4 className='mt-4'>AML (Anti-Money Laundering):</h4>
        <ul>
          <li>
            Actionable Steps: HOC conducts thorough checks to identify and
            prevent any potential money laundering activities.
          </li>
          <li>
            Details Needed: Information on the source of funds, the purpose of
            transactions, and other relevant financial details may be required.
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <p>
          <strong>Note:</strong> The KYC and AML processes are essential to
          ensure the security and legitimacy of transactions within HOC.
          Providing accurate and complete information during these verifications
          helps maintain a trustworthy and compliant environment for all
          community members. Your cooperation in these processes is greatly
          appreciated.
        </p>
      </Modal.Footer>
    </Modal>
  );
};

export default MyModal;
