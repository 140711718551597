/** @format */

import React from "react";
import { FaPlayCircle } from "react-icons/fa";

const data = [
  {
    id: 1,
    Image1: "assets/images/stay-at-home.svg",
    title: "Homeowners",
  },
  {
    id: 2,
    Image1: "assets/images/tenant-icon.svg",
    title: "Tenants",
  },
  {
    id: 3,
    Image1: "assets/images/broker.svg",
    title: "Real estate brokers",
  },
  {
    id: 4,
    Image1: "assets/images/saving.svg",
    title: "Financial lenders",
  },
  {
    id: 5,
    Image1: "assets/images/invest-money.svg",
    title: "Investor",
  },
  {
    id: 8,
    Image1: "assets/images/suppliers.svg",
    title: "Suppliers",
  },
  {
    id: 7,
    Image1: "assets/images/trading.svg",
    title: "Traders",
  },
  // {
  //   id: 8,
  //   Image1: "assets/images/eco-player.svg",
  //   title: "Eco-players",
  // },
  {
    id: 9,
    Image1: "assets/images/agreement.svg",
    title: "Lawyers",
  },
  {
    id: 10,
    Image1: "assets/images/notary.svg",
    title: "Notary",
  },
  {
    id: 11,
    Image1: "assets/images/bank.svg",
    title: "Banks",
  },
];

const Future = () => {
  return (
    <>
      <div className='future_section pt-5'>
        <div className='container'>
          <h2 className='text-center' data-aos='fade-down'>
            Property and Real estate
          </h2>
          <p className='text-center' data-aos='fade-down'>
            Examples of the eco-players in property and real estate are:
          </p>
          {/* {data.map((val, id) => {
              return (
                <div
                  className='col-lg-3 col-md-4  col-6   '
                  key={id}
                  data-aos='zoom-in'
                >
                  <div className='card'>
                    <div className='card-body'>
                      <img
                        src={val.Image1}
                        className='d-block '
                        style={{ width: "32%" }}
                        alt=''
                      />
                      <h6 className='mt-3'>{val.title}</h6>
                    </div>
                  </div>
                </div>
              );
            })} */}
          <div className='row '>
            <div className='col-lg-1 '></div>
            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/ewaseYtUbg8?autoplay=1&rel=0&controls=0&showinfo=0'>
                <div className='card-body'>
                  <img
                    src='assets/images/stay-at-home.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Homeowners</h6>
                  <FaPlayCircle className="fs-3" />
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/ncVCLcJOICk?autoplay=1&rel=0&controls=0&showinfo=0'>
               <div className='card-body'>
                  <img
                    src='assets/images/tenant-icon.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Tenants</h6>
                  <FaPlayCircle className="fs-3" />

                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/mfbL2lxL8qg?autoplay=1&rel=0&controls=0&showinfo=0'>
                <div className='card-body'>
                  <img
                    src='assets/images/broker.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Brokers</h6>
                  <FaPlayCircle className="fs-3" />

                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/g3KDZEeOhck?autoplay=1&rel=0&controls=0&showinfo=0'>
                <div className='card-body'>
                  <img
                    src='assets/images/saving.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Financial lenders</h6>
                  <FaPlayCircle className="fs-3" />

                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/rjTnD5YLrnA?autoplay=1&rel=0&controls=0&showinfo=0'>
                <div className='card-body'>
                  <img
                    src='assets/images/invest-money.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Investor</h6>
                  <FaPlayCircle className="fs-3" />

                </div>
              </div>
            </div>

            <div className='col-lg-1  col-sm-null'></div>
            <div className='col-lg-1  col-sm-null'></div>

            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/1IiFZVqUfZc?autoplay=1&rel=0&controls=0&showinfo=0'>
                <div className='card-body'>
                  <img
                    src='assets/images/suppliers.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Suppliers</h6>
                  <FaPlayCircle className="fs-3" />

                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/JNNtG7N3vjw?autoplay=1&rel=0&controls=0&showinfo=0'>
                <div className='card-body'>
                  <img
                    src='assets/images/trading.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Traders</h6>
                  <FaPlayCircle className="fs-3" />

                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/MLIx720QVp0?autoplay=1&rel=0&controls=0&showinfo=0'>
                <div className='card-body'>
                  <img
                    src='assets/images/agreement.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Lawyers</h6>
                  <FaPlayCircle className="fs-3" />

                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/08_z8fR7Fc8?autoplay=1&rel=0&controls=0&showinfo=0'>
                <div className='card-body'>
                  <img
                    src='assets/images/notary.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Notary</h6>
                  <FaPlayCircle className="fs-3" />

                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4  col-6   ' data-aos='zoom-in'>
              <div
                className='card'
                id='single_image'
                data-fancybox
                data-type='iframe'
                data-src='https://www.youtube.com/embed/ARLocxDUdrU?autoplay=1&rel=0&controls=0&showinfo=0'>
                <div className='card-body'>
                  <img
                    src='assets/images/bank.svg'
                    className='d-block '
                    style={{ width: "32%" }}
                    alt=''
                  />
                  <h6 className='mt-3'>Banks</h6>
                  <FaPlayCircle className="fs-3" />

                </div>
              </div>
            </div>
          </div>

          <div
            className='bottom_text d-flex justify-content-center'
            data-aos='fade-up'>
            <p className='text-center '>
              Almost €3 billion in property and real estate waiting to join and
              partner with HOC across the whole world.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Future;
