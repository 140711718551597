import React from 'react'
import { Link } from 'react-router-dom'

const Contact = () => {
  return (
    <>
          <div className="contact_sec">
      <div className="container ">
      <h3 className="text-center">Contact Us</h3>
      <div className="row">
        <div className="col-md-5 d-flex  align-items-center col-12">
          <div className="img_main " data-aos="flip-left">
          <img src="assets/images/logo.png" className='img-fluid' alt="" />
          </div>
        </div>
        <div className="col-md-7 col-12">
        <div className="   ">
          <div
            className="card shadow-lg "
            // style={{ width: "26rem" }}
            data-aos="zoom-in"
          >
            
            <div className="card-body  ">
              <form >
                <div className="input_icons">
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                <div style={{width: '44%'}}>
                <label className='mt-3'>Name:</label>
                <input
                  type="text"className="form-control" 
                  placeholder="Enter your Name" />

                <label className='mt-3'>Email:</label>
                <input
                  type="text"className="form-control" 
                  placeholder="Enter your Email" />
                  </div>

                <div>
                <label className='mt-3'>Message:</label>
                <textarea class="form-control" cols={32} rows={5} placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                </div>
                </div>
               
                <button
                  className="btn bg-primary text-white batn mt-4 "
                >
                  Submit
                </button>
                </div>
              </form>
             
            </div>
          </div>
        </div>

        </div>
      </div>
      </div>
      </div>
    </>
  )
}

export default Contact