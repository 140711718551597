/** @format */

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Inventions = () => {
  return (
    <>
      <div className='invention_sec'>
        <div className='container'>
          <h2 className='text-center' data-aos='fade-down'>
            Research-backed Inventions
          </h2>
          <p className='text-center' data-aos='fade-down'>
            Contributing to extensive scientific research from the world’s top
            academic institutions
          </p>
          {/* <div className="d-flex justify-content-around row_img">
            <img src="assets/images/a1.png" alt="" />
            <img src="assets/images/a2.png" alt="" />
            <img src="assets/images/a3.png" alt="" />
            <img src="assets/images/a4.png" alt="" />
            <img src="assets/images/a5.png" alt="" />
            <img src="assets/images/a6.png" alt="" />
          </div> */}
          <div className='row'>
            <div className='col-md-6'>
              <div className='card_main mt-5' data-aos='flip-left'>
                <h3>
                  To roll this out HOC has a road map build. Which will help
                  redefine the whole ecosystem.
                </h3>
                <p>
                  HOC is a project designed and developed by Screaming Goats
                  Innovations B.V.
                </p>
                {/* <div className="d-flex align-items-center card_bottom">
                  <img
                    src="assets/images/w1.webp"
                    style={{ width: "15%" }}
                    alt=""
                  />
                  <div className="mx-3">
                    <h4>Susan Nesbitt</h4>
                    <h5>Head of the Global Innovators Community, WEF</h5>
                  </div>
                </div> */}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='card_main mt-5' data-aos='flip-left'>
                <Carousel
                  responsive={responsive}
                  arrows
                  autoPlay
                  autoPlaySpeed={3000}
                  draggable
                  showArrows={true}
                  focusOnSelect={false}
                  infiniteLoop={true}
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  showThumbs={false}
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}>
                  <div className='card_right'>
                    <p>
                      In these times we see that a lot of the property and real
                      estate portfolios are in the hands of big institutional
                      investment companies.
                    </p>
                  </div>
                  <div className='card_right'>
                    <p>
                      What we see is that the housing markets gets overpriced
                      very fast. This event creates problems for the housing
                      market. Especially for the low budget hard working
                      persons.
                    </p>
                  </div>
                  <div className='card_right'>
                    {/* <div className="d-flex">
                      <img
                        src="assets/images/p3.webp"
                        style={{ width: "18%" }}
                        alt=""
                      />
                    </div> */}
                    {/* <h3 className="my-3">
                      A blockchain-verified platform for selling houses
                    </h3> */}
                    <p>
                      We have seen in the Netherlands that 900.000 houses have
                      to be built by 2030. Which one third has to be affordable.
                    </p>
                  </div>
                  <div className='card_right'>
                    <p>
                      We see that students, starters have trouble of getting
                      affordable houses. But also the citizens of the
                      Netherlands cannot get a house to rent or have to pay a
                      very high price to rent or buy.
                    </p>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inventions;
