/** @format */

import React from "react";
import { Link } from "react-router-dom";

const data = [
  {
    id: 1,
    image:
      "https://miro.medium.com/v2/resize:fit:828/format:webp/1*WFoFmMMZVXuuvTmmCdfeHg.png",
    title:
      "Discover the Future of Real Estate: Welcome to Home Owners Club (HOC)",
    link: "https://medium.com/@homeownersclub/discover-the-future-of-real-estate-welcome-to-home-owners-club-hoc-e0c412ac021b",
  },
];

const BlogAndVlog = () => {
  return (
    <>
      <div className='blog_sec'>
        <div className='bg_img'>
          <div className='container'>
            <h3>Home Owners Club Blog</h3>
            <div className='d-flex justify-content-center mt-4'>
              <a
                href='https://medium.com/@homeownersclub'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <button className='btn-style'>Follow</button>
              </a>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-md-8 mt-5'>
              <div className='card'>
                <div className='card_img2 text-end'>
                  <div className='p-5 mt-5'></div>
                </div>
              </div>
            </div>
            {data.map(res => {
              return (
                <div className='col-md-4 col-12 mt-5'>
                  <div className='card'>
                    <div className='card_img'>
                      <img
                        src={res.image}
                        className='card-img-top  img-fluid'
                        alt='...'
                      />
                    </div>
                    <div className='card-body'>
                      <h5 className='card-title mt-3'>{res.title}</h5>
                      <a
                        href={res.link}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-warning'>
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogAndVlog;
