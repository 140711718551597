/** @format */

import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    scrollToTop();
  }, []);
  return (
    <>
      {/* <div className="bg_img2">
        <div className="container">
          <h3>About</h3>
          <div className="d-flex justify-content-center mt-4">
          </div>
        </div>
      </div> */}
      <div className='hero_section'>
        <div className='container hero_inner'>
          <div className='row'>
            <div className='col-lg-6 col-md-12  col-12'></div>
            <div className='col-md-6 col-12 '>
              <h2> About US</h2>
              <br />
              <p>
                Home Owners Club (HOC) is more than just a real estate
                investment platform; it's a visionary project committed to
                reshaping the property and real estate landscapes worldwide.
                Founded on principles of transparency, security, and
                community-driven growth, HOC seeks to empower individuals from
                all walks of life to collectively own a significant stake in the
                property market. Spearheaded by Screaming Goats Innovations B.V.
                (SGI), our platform combines the expertise of real estate and
                blockchain to redefine the way HOC invest in and engage with real
                estate. Join us in this transformative journey toward a more
                accessible and collaborative real estate future.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container about-uss mt-5'>
        <div className='row'>
          <div className='col-md-6 col-12 '>
            <h2> Our Mission and Vision</h2>
            <br />
            <p>
              <strong style={{ color: "#fcd535" }}>Mission:</strong> At HOC, our
              mission is clear - HOC aim to democratize real estate investment,
              breaking down the barriers that have historically restricted
              access to property ownership. HOC believe that every individual,
              regardless of their financial status, deserves the opportunity to
              participate in and benefit from the world of real estate
            </p>
            <p>
              <strong style={{ color: "#fcd535" }}>Vision:</strong> HOC
              envisions a future where property and real estate investments are
              no longer the exclusive domain of the wealthy few. Instead, HOC
              see a world where a global community of small investors
              collectively owns and actively shapes the real estate landscape.
            </p>
          </div>
          <div className='col-lg-6 col-md-12 m-auto col-12'>
            <img
              src='assets/images/collaborate.svg'
              className='w-50 m-auto d-block'
              alt=''
            />
          </div>
        </div>
      </div>

      <div className='container about-uss mt-5'>
        <div className='row'>
          <div className='col-lg-6 col-md-12  col-12 m-auto '>
            <img
              src='https://homeownersclub.io/assets/images/c1g.png'
              className='w-75 m-auto d-block'
              alt=''
            />
          </div>
          <div className='col-md-6 col-12 '>
            <h2> What Sets Us Apart?</h2>
            <br />
            <p>
              <strong>
                HOC stands out in a rapidly evolving real estate market, thanks
                to its unique approach:
              </strong>
            </p>
            <p>
              <strong style={{ color: "#fcd535" }}>Community-Driven:</strong>{" "}
              Our platform leverages a hybrid and transparent model that marries
              the capabilities of blockchain technology with rigorous regulatory
              compliance. This approach positions us as a trusted and
              responsible steward of real estate investments, fostering
              collaboration with governments and financial institutions while
              maintaining the highest standards of transparency and
              accountability.
            </p>
            <p>
              <strong style={{ color: "#fcd535" }}>Vision:</strong> Central to
              our philosophy is the belief in community-driven investments. HOC
              members come together to invest collectively in properties, share
              in the resulting profits, and collectively influence the direction
              of the platform. Together, HOC redefine and democratize real
              estate investment.
            </p>
          </div>
            <p>
              <strong style={{ color: "#fcd535" }}>
                Innovation at the Core:
              </strong>{" "}
              HOC are unwavering in our commitment to innovation. Our ambitious
              roadmap includes deep integration with the metaverse, the
              development of a decentralized exchange (DEX), and the transition
              into a fully decentralized autonomous organization (DAO).{" "}
            </p>
            <p>
              HOC firmly believe that blockchain technology holds the potential
              to reimagine the real estate industry for the benefit of all
            </p>
        </div>
      </div>

     

      <div className='container about-uss mt-5'>
        <div className='row'>
          <div className='col-md-6 col-12 m-auto text-center'>
            <h2> Join the HOC Community</h2>
            <br />
            <p>
              By becoming a part of the Home Owners Club, users join a
              transformative movement that is working to make real estate
              investment accessible and inclusive for everyone. HOC invites
              users to explore our platform, delve deeper into our innovative
              solutions, and join us on this thrilling journey to redefine the
              future of real estate.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
