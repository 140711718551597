/** @format */

import React, { useState } from "react";
import axios from "axios";
const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [successes, setSuccesses] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = event => {
    setEmail(event.target.value);
    setIsEmailValid(true);
    setSuccesses("");
    setError("");
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setIsEmailValid(true);

    if (email === "" || !/\S+@\S+\.\S+/.test(email)) {
      setIsEmailValid(false);
    } else {
      setIsLoading(true);

      try {
        const response = await axios.post(
          "https://hocnewsletter.onrender.com/subscribe",
          { email },
        );
        setIsEmailValid(true);
        setSuccesses(
          `Thank you for subscribing with ${email}, Kindly check your inbox`,
        );
        setEmail("");
      } catch (error) {
        setError(
          "An error occurred while subscribing. Please try again later.",
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className='newsletter_sec'>
        <div className='container'>
          <div className='bg_img'>
            <div className='row px-4 py-3'>
              <div className='col-md-8 m-auto ' data-aos='zoom-in'>
                <h2>Subscribe to Newsletter</h2>
                <p>
                  Receive the
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    Real Estate, Proptech & Web3 Pulse: Your Weekly Digest
                  </span>{" "}
                  covering the latest innovations and news
                </p>
                <form className='contact-right mx-5' onSubmit={handleSubmit}>
                  <input
                    type='email'
                    name='email'
                    // required={true}
                    id='email'
                    value={email}
                    onChange={handleInput}
                    placeholder='Your email here'
                  />
                  <button
                    className=' btn-style '
                    type='submit'
                    disabled={isLoading}>
                    {isLoading ? "Loading..." : "Subscribe"}
                  </button>
                </form>
                {!isEmailValid ? (
                  <p className='fs-6 fw-bold text-danger mx-5'>
                    Please enter a valid email address
                  </p>
                ) : null}
                {successes ? (
                  <p className='fs-6 fw-bold text-warning mx-5'>{successes}</p>
                ) : null}
                {error ? (
                  <p className='fs-6 fw-bold text-danger mx-5'>{error}</p>
                ) : null}
              </div>
              <div className='col-md-4 '>
                <div
                  className='img_main position-relative'
                  data-aos='fade-down'>
                  <img
                    src='https://homeownersclub.io/assets/images/strate.jpg'
                    className='img-fluid rounded-4 position-absolute trs'
                    alt=''
                  />
                  <img
                    src='https://homeownersclub.io/assets/images/strate.jpg'
                    className='img-fluid rounded-4 '
                    alt=''
                  />
                  <div className='trs'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
