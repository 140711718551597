/** @format */

import React from "react";
import Hero from "../components/Elements/Hero";
import Future from "../components/Elements/Future";
import Featured from "../components/Elements/Featured";
import Funds from "../components/Elements/Funds";
import Escrow from "../components/Elements/Escrow";
import Newsletter from "../components/Elements/Newsletter";
import Card from "../components/Elements/Card";
import Sections from "../components/Elements/Sections";
import Client from "../components/Elements/Client";
import Inventions from "../components/Elements/Inventions";
import Faqs from "../components/Utils/Faqs";
import {
  FaTwitter,
  FaTelegram,
  FaYoutube,
  FaDiscord,
  FaMedium,
} from "react-icons/fa";
import Roadmap from "../components/Elements/Roadmap";
import Tokenomics from "../components/Elements/Tokenomics";
const Home = () => {
  return (
    <>
      <Hero />
      <Future />
      {/* <Featured /> */}
      <Funds />
      <Inventions />
      {/* <Client/> */}
      <Escrow />
      <Sections />
      <Newsletter />

      <Roadmap />
      <Tokenomics />
      <Card />
      <Faqs />
      {/*==================== SCROLL TOP ====================*/}

      <div className='fixed-icons'>
        <a
          href='https://twitter.com/Home_OwnersClub'
          target='_blank'
          rel='noreferrer'>
          <FaTwitter size={25} className='mt-3' />
        </a>

        <a href='https://t.me/Home_OwnersClub' target='_blank' rel='noreferrer'>
          <FaTelegram size={25} className='mt-3' />
        </a>
        <a href='http://discord.gg/sxTZuv8z' target='_blank' rel='noreferrer'>
          <FaDiscord size={25} className='mt-3' />
        </a>
        <a
          href='https://medium.com/@homeownersclub'
          target='_blank'
          rel='noreferrer'>
          {" "}
          <FaMedium size={25} className='mt-3' />
        </a>

        <a
          href='https://www.youtube.com/@Homeownerclub'
          target='_blank'
          rel='noreferrer'>
          {" "}
          <FaYoutube size={25} className='mt-3' />
        </a>
      </div>
    </>
  );
};

export default Home;
