/** @format */

import React from "react";
import { BiPlayCircle } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaBook } from "react-icons/fa";

const Hero = () => {
  return (
    <>
      <div className='hero_section container-fluid '>
        <div className='container hero_inner'>
          <div className='row pt-5'>
            <div className='col-lg-6 m-auto'>
              <h2> Home Owners Club</h2>
              <br />
              <p>
                is a platform build for everyone who wants to participate in the
                international property and real estate market.
              </p>
              <p>
                Within the platform we will facilitate the opportunities for the
                traditional eco-players that are now in the property and real
                estate ecosystem to innovate with us
              </p>
              <div className="pt-3">
                <a
                  className='btn-style text-black '
                  href='https://homeownersclub.io/whitepaper.pdf'
                  target='_blank'
                  rel='noreferrer'>
                  <FaBook /> &nbsp; <strong>Download Whitepaper</strong>{" "}
                  
                </a>
              </div>
            </div>
            <div className='col-lg-6  m-auto'>
              <div className='video_main'>
                <iframe
                  allow='autoplay'
                  src='https://www.youtube.com/embed/TvDn597SZm4?si=KnhhpIU1MFEJHGE5'
                  frameBorder={0}
                  allowFullScreen
                  wmode='opaque'
                  id='fitvid458108'
                  title='About HOC'
                  data-ready='true'
                  className='cf-sticky-video-active rounded-4'
                  contentEditable='false'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
