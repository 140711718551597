import React, { useEffect } from "react";
import { Link } from 'react-router-dom'

const Register = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    scrollToTop();
  }, []);
  return (
    <>
       <div className="bg_color">
      <div className="container " style={{ height: "100%",paddingBottom: '120px' }}>
        <div className=" d-flex  justify-content-center py-5">
          <div
            className="card shadow-lg   py-3 "
            style={{ width: "26rem" }}
            data-aos="zoom-in"
          >
            <h3 className="text-center">Register</h3>
            <div className="card-body  ">
              <form >
                <div className="input_icons">
                <label>Email:</label>
                
                <input
                  type="email"className="form-control" 
                  placeholder="Enter your Email" />

                <label className='mt-3'>Contact:</label>
                
                <input
                  type="text"className="form-control" 
                  placeholder="Enter your Contact" />
                <label className='mt-3'>Password:</label>
            
                <input type="password" 
                  className="form-control"
                  placeholder="Enter Password"/>
                <label className='mt-3'>Confirm Password:</label>
            
                <input type="password" 
                  className="form-control"
                  placeholder="Enter Confirm Password"/>
                <button
                  className="btn bg-primary text-white batn mt-4 w-100"
                >
                  Register
                </button>
                </div>
              </form>
              <div className='d-flex justify-content-center align-items-center '>
              <div className="d-flex text-center align-items-center botm_link  mt-3">
              Already have an account!
                <Link to="/login" className="">
                  Login
                </Link>
              </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default Register