/** @format */

import React from "react";
import MyModal from "../Utils/MyModal";
import { FaExternalLinkAlt } from "react-icons/fa";

const Sections = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const handleShow = () => setModalShow(true);

  const data1 = [
    {
      id: 1,
      image: "assets/images/registration.svg",
      title: "KYC/AML registration",
      modal: handleShow,
    },
    {
      id: 2,
      image: "assets/images/environment.svg",
      title: "Highly secured environment",
    },
    {
      id: 3,
      image: "assets/images/invest-money.svg",
      title: "Investor environment",
    },
  ];

  const data2 = [
    {
      id: 1,
      image: "assets/images/real-estate-market.svg",
      title: "Real estate management environment Self or through SGI",
    },
  ];

  const data3 = [
    {
      id: 1,
      image: "assets/images/security-scan.svg",
      title: "Audit environment",
    },
  ];
  return (
    <>
      <div className='section'>
        <div className='container'>
          <h2 className='text-center' data-aos='fade-down'>
            HOC Function
          </h2>
          <p className='text-center' data-aos='fade-down'>
            Most important is that HOC has the following functions within its
          </p>
          <h4 className='mt-3'>Platform For the customers:</h4>
          <div className='row my-2'>
            {data1.map((res, id) => {
              return (
                <div className='col-md-4 mt-3' data-aos='flip-right' key={id}>
                  <div
                    className={
                      res?.modal ? "card_main active-card" : "card_main"
                    }
                    onClick={res?.modal}>
                    <div className='d-flex card_left align-items-center'>
                      <img src={res.image} className='img-fluid' alt='' />
                      <div className='mx-3 card_right'>
                        <h3>{res.title}</h3>
                        <p>{res.para}</p>
                        {res.modal ? (
                          <span>
                            Learn More <FaExternalLinkAlt className='mx-2' />
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className='row mt-5'>
            <div className='col-md-6'>
              <h4 className='mt-3'>For home owners:</h4>
              <div className='row my-2'>
                {data2.map((res, id) => {
                  return (
                    <div
                      className='col-md-12 mt-3'
                      data-aos='flip-right'
                      key={id}>
                      <div className='card_main'>
                        <div className='d-flex card_left align-items-center'>
                          <img src={res.image} className='img-fluid' alt='' />
                          <div className='mx-3 card_right'>
                            <h3>{res.title}</h3>
                            <p>{res.para}</p>
                            {/* <a href="#">Learn More</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='col-md-6'>
              <h4 className='mt-3'>For lawyers or notary:</h4>
              <div className='row my-2'>
                {data3.map((res, id) => {
                  return (
                    <div
                      className='col-md-12 mt-3'
                      data-aos='flip-right'
                      key={id}>
                      <div className='card_main'>
                        <div className='d-flex card_left align-items-center'>
                          <img src={res.image} className='img-fluid' alt='' />
                          <div className='mx-3 card_right'>
                            <h3>{res.title}</h3>
                            <p>{res.para}</p>
                            {/* <a href="#">Learn More</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <MyModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </>
  );
};

export default Sections;
