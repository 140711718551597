/** @format */

import React from "react";

const Funds = () => {
  return (
    <>
      <div className='funds_sec'>
        <div className='container'>
          <div className='bg_img'>
            <div className='row'>
              <div className='col-md-6 m-auto' data-aos='zoom-in'>
                <h2>HOC Win</h2>
                <p>
                  HOC will try to create win win situations <br /> for every
                  eco-player in this market.
                </p>
                <div className='d-flex bottom_btn mb-3'>
                  <button className='btn-style'>Get started</button>
                </div>
              </div>
              <div className='col-md-4 d-flex justify-content-center'>
                <div className='img_main' data-aos='fade-down'>
                  <img
                    src='assets/images/win.svg'
                    className='img-fluid'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Funds;
