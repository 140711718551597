/** @format */

import React from "react";
import {
  FaTwitter,
  FaTelegram,
  FaYoutube,
  FaDiscord,
  FaMedium,
} from "react-icons/fa";
const Footer = () => {
  return (
    <footer className='footer text-center text-lg-start text-light'>
      <div className='container'>
        <div className='row list'>
          <div className='col-md-4 pt-4  d-flex flex-row justify-content-center align-items-center  col-12 icons px-4'>
            <img src='assets/images/logo.png' style={{ width: "20%" }} alt='' />
            &nbsp; <h2>Home owners Club</h2>
          </div>

          <div className='col-md-8 text-end '>
            <div className='footer-icons   m-auto mt-5'>
            <a
          href='https://twitter.com/Home_OwnersClub'
          target='_blank'
          rel='noreferrer'>
          <FaTwitter size={25} className='mt-3' />
        </a>

        <a href='https://t.me/Home_OwnersClub' target='_blank' rel='noreferrer'>
          <FaTelegram size={25} className='mt-3' />
        </a>
        <a href='http://discord.gg/sxTZuv8z' target='_blank' rel='noreferrer'>
          <FaDiscord size={25} className='mt-3' />
        </a>
        <a
          href='https://medium.com/@homeownersclub'
          target='_blank'
          rel='noreferrer'>
          {" "}
          <FaMedium size={25} className='mt-3' />
        </a>

        <a
          href='https://www.youtube.com/@Homeownerclub'
          target='_blank'
          rel='noreferrer'>
          {" "}
          <FaYoutube size={25} className='mt-3' />
        </a>
            </div>
          </div>
        <p className='pt-2 text-center '>
          © 2023 HOC, Inc. All rights reserved
        </p>
        </div>
     

      </div>
    </footer>
  );
};

export default Footer;
